<template>
  <div class="flex-w-col">
    <LearnHeader
      bPath='/learn/csharp/12'
      fPath='/learn/csharp/14'
      title='Abstract'
    />
  </div>
</template>

<script>
import LearnHeader from '@/components/LearnHeader.vue'

export default {
  components: { LearnHeader },
}
</script>
